import type { Currency, Money } from ".";

export enum InstantPayoutStatus {
  FAILED = "failed",
  SUCCEEDED = "succeeded",
  PROCESSING = "processing",
}

export const InstantPayoutStatusText: Record<InstantPayoutStatus, string> = {
  [InstantPayoutStatus.FAILED]: "Failed",
  [InstantPayoutStatus.SUCCEEDED]: "Paid",
  [InstantPayoutStatus.PROCESSING]: "Processing",
};

export enum InstantPayoutFeeStatus {
  FAILED = "failed",
  PAID = "paid",
  PROCESSING = "processing",
}

export type InstantPayout = {
  id: string;
  amount: Money;
  fee: Money & { status: InstantPayoutFeeStatus; feePercent: number };
  status: InstantPayoutStatus;
  createdAt: Date;
  stripePayoutId?: string;
};

export enum PaymentMethodType {
  CARD = "card",
  BANK_ACCOUNT = "bank_account",
}

export enum CardBrand {
  VISA = "Visa",
  MASTER_CARD = "MasterCard",
  DISCOVER = "Discover",
  DINERS_CLUB = "Diners Club",
  JCB = "JCB",
  UNION_PAY = "UnionPay",
  AMERICAN_EXPRESS = "American Express",
}

type PaymentMethodBase = {
  id: string;
  currency: Currency;
  last4: string;
};

export type CardPaymentMethod = PaymentMethodBase & {
  type: PaymentMethodType.CARD;
  brand: CardBrand;
};

export type BankAccountPaymentMethodMethod = PaymentMethodBase & {
  type: PaymentMethodType.BANK_ACCOUNT;
  bankName: string;
};
